$table-labels-font-size: 12px;
$table-labels-font-weight: 700;
$table-labels-font-color: $color-primary;
$table-row-border-color: color('gray', 'lighten-4');
$table-cell-color: color('gray', 'lighten-2');
$table-cell-padding: 1rem .25rem;
$table-selected-bg: color('gray', 'lighten-5');
$table-switch-border-color: color('gray', 'lighten-4');
$table-switch-bg: color('gray', 'lighten-5');
$table-switch-inactive-bg: color('gray', 'lighten-3');
$table-switch-active-bg: $color-primary;
$table-responsive-card-border-radius: $radio-default;
$table-responsive-card-padding: 0.5rem 1rem;
$table-responsive-card-margin: 0 0 1rem 0;
$table-responsive-card-table-row-max-width: 150px;

.table-component{
  .table-labels{
    @include flexbox();
    @include flex-direction(row);
    border-bottom: 1px solid $table-row-border-color;
    .label-column{
      @include flexbox();
      @include flex-direction(column);
      font-size: $table-labels-font-size;
      font-weight: $table-labels-font-weight;
      color: $table-labels-font-color;
      padding: $table-cell-padding;
    }
  }
  .table-rows{
    width: 100%;
    .table-row-item{
      width: 100%;
      @include flexbox();
      @include flex-direction(row);
      @include align(center);
      border-bottom: 1px solid $table-row-border-color;
      &.row-active{
        background: $table-selected-bg;
      }
      .table-row-cell{
        padding: $table-cell-padding;
        color: $table-cell-color;
        &.image{
          .image-container{
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
          }
        }
        &.switch{
          .switch-container{
            background: $table-switch-bg;
            display: block;
            width: 42px;
            height: 26px;
            border: 2px solid $table-switch-border-color;
            border-radius: 13px;
            position: relative;
            &.active-switch, &.inactive-switch{
              &:after{
                content: '';
                display: block;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
              }
            }
            &.active-switch{
              &:after{
                left: inherit;
                right: 0;
                background: $table-switch-active-bg;
              }
            }
            &.inactive-switch{
              &:after{
                background: $table-switch-inactive-bg;
              }
            }
          }
        }
        &.click-row{
          cursor: pointer;
        }
      }
    }
  }
}


// Responsive

@media #{$medium-and-down} {
  .table-component {
    &.card-responsive {
      .table-labels {
        display: none;
      }
      .table-rows {
        .table-row-item {
          @include flex-direction(column);
          border: 1px solid $table-row-border-color;
          border-radius: $table-responsive-card-border-radius;
          padding: $table-responsive-card-padding;
          margin: $table-responsive-card-margin;
          display: block;
          width: 100%;
          .table-row-cell {
            display: block;
            width: 100%!important;
            position: relative;
            padding-top: 1rem;
            padding-bottom: 0;
            &.no-value{
              padding: $table-cell-padding;
              padding-top: 0!important;
              padding-bottom: 0!important;
            }
            &.image {
              padding: 0;
              max-width: $table-responsive-card-table-row-max-width;
            }
            &.custom {
              text-align: left;
            }
            &:after{
              content: attr(data-title);
              position: absolute;
              top: .15rem;
              left: .25rem;
              font-size: $table-labels-font-size;
              color: $table-labels-font-color;
              font-weight: $table-labels-font-weight;
            }
          }
        }
      }
    }
  }
}
