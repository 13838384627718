#__next{
  width: 100%;
  overflow-x: hidden;
  min-height: 100%;
  .main-layout{
    position: relative;
    min-height: 100%;
    left: 0;
    @include transition(all .4s)
  }
}
