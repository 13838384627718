$tooltip-color: $color-white;
$tooltip-background: $color-gray;
$tooltip-radius: $radio-small;
$tooltip-boxShadow: $z-depth-1;
$tooltip-padding: .5rem;

.tooltip-component {
  position: relative;
  display: inline-block;
  .tooltip-container {
    z-index: 5;
    position: absolute;
    color: $tooltip-color;
    background-color: $tooltip-background;
    border-radius: $tooltip-radius;
    box-shadow: $tooltip-boxShadow;
    padding: $tooltip-padding;
  }
}
