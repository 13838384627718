//padding

.p-lg-all {
  padding: 2rem;
}
.p-all {
  padding: 1rem;
}
.p-s-all {
  padding: 0.5rem;
}
.p-none {
  padding: 0!important;
}

.p-l-lg {
  padding-left: 2rem;
}
.p-r-lg {
  padding-right: 2rem;
}
.p-b-lg {
  padding-bottom: 2rem;
}
.p-t-lg {
  padding-top: 2rem;
}

.p-l {
  padding-left: 1rem;
}
.p-r {
  padding-right: 1rem;
}
.p-b {
  padding-bottom: 1rem;
}
.p-t {
  padding-top: 1rem;
}

.p-l-s {
  padding-left: 0.5rem;
}
.p-r-s {
  padding-right: 0.5rem;
}
.p-b-s {
  padding-bottom: 0.5rem;
}
.p-t-s {
  padding-top: 0.5rem;
}

.p-t-none {
  padding-top: 0!important;
}
.p-l-none {
  padding-left: 0!important;
}
.p-r-none {
  padding-right: 0!important;
}
.p-b-none {
  padding-bottom: 0!important;
}

//margin

.m-lg-all {
  margin: 2rem;
}
.m-all {
  margin: 1rem;
}
.m-all-s {
  margin: 0.5rem;
}

.m-auto {
  margin: auto;
}

.m-l-lg {
  margin-left: 2rem;
}
.m-r-lg {
  margin-right: 2rem;
}
.m-b-lg {
  margin-bottom: 2rem;
}
.m-t-lg {
  margin-top: 2rem;
}

.m-l {
  margin-left: 1rem;
}
.m-r {
  margin-right: 1rem;
}
.m-b {
  margin-bottom: 1rem;
}
.m-t {
  margin-top: 1rem;
}

.m-l-s {
  margin-left: 0.5rem;
}
.m-r-s {
  margin-right: 0.5rem;
}
.m-b-s {
  margin-bottom: 0.5rem;
}
.m-t-s {
  margin-top: 0.5rem;
}

.m-b-none {
  margin-bottom: 0!important;
}
.m-t-none {
  margin-top: 0!important;
}
.m-l-none {
  margin-left: 0!important;
}
.m-r-none {
  margin-right: 0!important;
}

.m-none {
  margin: 0!important;
}
