[class*="icon-arrow"]{
  position: relative;
  font-size: 1em;
  &:after{
    content: '\00a0';
  }
  &:before{
    content: '';
    display: block;
    width: 1em;
    height: 1em;
    border: 2px solid $color-gray;
    border-left: 0;
    border-bottom: 0;
    position: absolute;
    top: calc(50% - .5em);
  }
}
.icon-arrow-left{
  &:before{
    left: calc(50% - .25em);
    @include transform(rotate(-135deg))
  }
}

.icon-arrow-right{
  &:before{
    right: calc(50% - .25em);
    @include transform(rotate(45deg))
  }
}

.icon-arrow-down{
  &:before{
    right: calc(50% - .5em);
    top: calc(50% - 0.75em);
    @include transform(rotate(135deg))
  }
}

[class*="icon-close"]{
  position: relative;
  font-size: 1em;
  width: 1em;
  height: 1em;
  display: inline-block;
  &:before, &:after {
    position: absolute;
    left: .5em;
    content: ' ';
    height: 1em;
    width: .1em;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
