$date-range-bg: $color-white;
$date-range-label-color: $color-primary;
$date-range-color: $color-gray;
$date-range-border: 1px solid color('gray', 'lighten-4');
$date-range-radius: $radio-small;
$date-range-fontsize: $fontsize-default;
$date-range-height: 68px;
$date-range-error-color-warning: $color-warning;

.date-range{
  border: $date-range-border;
  border-radius: $date-range-radius;
  min-height: $date-range-height;
  @include flexbox();
  @include align(center);
  .date-range-button{
    background: $date-range-bg;
    width: 100%;
  }
  .date-range-label{
    color: $date-range-label-color;
  }
  .date-range-value{
    color: $date-range-color;
    font-size: $date-range-fontsize;
  }
  .range-content{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 800;
    @include flexbox();
    @include justify-content(center);
    @include align(center);
    .close-bg{
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(color('gray', 'lighten-3'), .75);
      z-index: 900;
    }
    .picker-area{
      z-index: 999;
      border-radius: 8px;
      overflow: hidden;
    }
  }
  .warning-content, .error-message {
    color: $date-range-error-color-warning;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
}
