$radio-button-item-width-height: 30px;
$radio-button-item-border-width: 1px;
$radio-button-list-item-padding: .6rem;
$radio-button-list-item-font-color: $color-gray;
$radio-button-list-item-font-size: $fontsize-small;
$radio-button-list-item-radio-radius: 50%;
$radio-button-list-item-background: $color-white;
$radio-button-list-item-radio-color: color('gray', 'lighten-2');
$radio-button-list-item-radio-error-color: $color-warning;
$radio-button-list-item-font-disable-color: color('gray', 'lighten-3');
$radio-button-list-item-radio-disable-color: color('gray', 'lighten-3');
$radio-button-list-item-selected-border-color: $color-secondary;
$radio-button-list-item-selected-background: $color-secondary;
$radio-button-list-item-selected-border-separator: 5px solid $color-white;
$radio-button-list-item-selected-bullet-width-height: 10px;

.radio-button-component{
  &.radio-button-component-error{
    .radio-list-item{
      &:after{
        border-color: $radio-button-list-item-radio-error-color;
      }
    }
  }
  &.disabled-radio-button{
    .radio-list-item{
      &:after, input{
        cursor: default;
      }
      &:after{
        border-color: $radio-button-list-item-radio-disable-color;
      }
      &.selected {
        &:after {
          border-color: $radio-button-list-item-radio-disable-color;
          background: $radio-button-list-item-radio-disable-color;
        }
        &:before{
          cursor: default;
        }
      }
      label{
        color: $radio-button-list-item-font-disable-color;
        cursor: default;
      }
    }
  }
  .radio-list-item{
    padding: $radio-button-list-item-padding $radio-button-list-item-padding $radio-button-list-item-padding calc(#{$radio-button-item-width-height} + 1rem);
    position: relative;
    &:after, input{
      cursor: pointer;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: calc(-#{$radio-button-item-width-height} / 2);
      width: $radio-button-item-width-height;
      height: $radio-button-item-width-height;
      border: $radio-button-item-border-width solid $radio-button-list-item-radio-color;
      box-sizing: border-box;
      border-radius: $radio-button-list-item-radio-radius;
      background: $radio-button-list-item-background;
    }
    input{
      opacity: 0;
      z-index: 5;
    }
    label{
      font-size: $radio-button-list-item-font-size;
      color: $radio-button-list-item-font-color;
      cursor: pointer;
    }
    &.selected{
      &:after{
        border-color: $radio-button-list-item-selected-border-color;
        background: $radio-button-list-item-selected-background;
      }
      &:before{
        cursor: pointer;
        z-index: 2;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        margin-left: calc((#{$radio-button-item-width-height} - #{$radio-button-list-item-selected-bullet-width-height}) / 2);
        margin-top: calc(-#{$radio-button-list-item-selected-bullet-width-height} / 2);
        width: $radio-button-list-item-selected-bullet-width-height;
        height: $radio-button-list-item-selected-bullet-width-height;
        box-sizing: border-box;
        border: $radio-button-list-item-selected-border-separator;
        border-radius: $radio-button-list-item-radio-radius;
      }
    }
  }
  .error{
    width: 100%;
    text-align: right;
  }
}
