/// borders

.no-border {
  border: 0;
}
.no-border-b {
  border-bottom-width: 0 !important;
}
.no-border-t {
  border-top-width: 0 !important;
}
.no-border-l {
  border-left-width: 0 !important;
}
.no-border-r {
  border-right-width: 0 !important;
}
.border {
  border-style: solid;
  border-width: 1px;
}
.border-dashed {
  border-style: dashed !important;
}
.border-t {
  border-top-style: solid;
  border-top-width: 1px;
}
.border-l {
  border-left-style: solid;
  border-left-width: 1px;
}
.border-r {
  border-right-style: solid;
  border-right-width: 1px;
}
.border-b {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.border-w-2 {
  border-width: 2px !important;
}
.border-t-w-2 {
  border-top-width: 2px !important;
}
.border-l-w-2 {
  border-left-width: 2px !important;
}
.border-r-w-2 {
  border-right-width: 2px !important;
}
.border-b-w-2 {
  border-bottom-width: 2px !important;
}
.border-w-3 {
  border-width: 3px !important;
}
.border-t-w-3 {
  border-top-width: 3px !important;
}
.border-l-w-3 {
  border-left-width: 3px !important;
}
.border-r-w-3 {
  border-right-width: 3px !important;
}
.border-b-w-3 {
  border-bottom-width: 3px !important;
}
.border-w-4 {
  border-width: 4px !important;
}
.border-t-w-4 {
  border-top-width: 4px !important;
}
.border-l-w-4 {
  border-left-width: 4px !important;
}
.border-r-w-4 {
  border-right-width: 4px !important;
}
.border-b-w-4 {
  border-bottom-width: 4px !important;
}
.border-w-5 {
  border-width: 5px !important;
}
.border-t-w-5 {
  border-top-width: 5px !important;
}
.border-l-w-5 {
  border-left-width: 5px !important;
}
.border-r-w-5 {
  border-right-width: 5px !important;
}
.border-b-w-5 {
  border-bottom-width: 5px !important;
}
