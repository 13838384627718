@font-face {
  font-family: "Roboto";
  src: url('../../assets/font/Roboto-Regular.ttf');
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url('../../assets/font/Roboto-Black.ttf');
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url('../../assets/font/Roboto-Light.ttf');
  font-weight: 300;
}
