//columns small
$i: 1;
@while $i <= $num-cols {
  $perc: unquote((100 / ($num-cols / $i)) + '%');
  .col-s#{$i} {
    width: $perc;
  }
  $i: $i + 1;
}

//columns mobile
@media #{$small-and-up} {
  $i: 1;
  @while $i <= $num-cols {
    $perc: unquote((100 / ($num-cols / $i)) + '%');
    .col-m#{$i} {
      width: $perc;
    }
    $i: $i + 1;
  }
}

//columns medium
@media #{$medium-and-up} {
  $i: 1;
  @while $i <= $num-cols {
    $perc: unquote((100 / ($num-cols / $i)) + '%');
    .col-l#{$i} {
      width: $perc;
    }
    $i: $i + 1;
  }
}

//columns large
@media #{$large-and-up} {
  $i: 1;
  @while $i <= $num-cols {
    $perc: unquote((100 / ($num-cols / $i)) + '%');
    .col-xl#{$i} {
      width: $perc;
    }
    $i: $i + 1;
  }
}


//columns big
@media #{$extra-large-and-up} {
  $i: 1;
  @while $i <= $num-cols {
    $perc: unquote((100 / ($num-cols / $i)) + '%');
    .col-big#{$i} {
      width: $perc;
    }
    $i: $i + 1;
  }
}
