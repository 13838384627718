$header-background: $color-white;
$header-border-bottom: color('gray', 'lighten-6') solid 1px;
$aside-width: 84px;
$aside-width-small: 56px;
$nav-height: 64px;
$navs-space: 4px;

body{
  padding-top: $nav-height;
  &.no-nav{
    .header{
      width: 100%;
      left: 0;
    }
  }
}

.header{
  height: $nav-height;
  width: calc(100% - #{$aside-width} - #{$navs-space} - #{$navs-space} - #{$navs-space});
  left: calc(#{$aside-width} + #{$navs-space} + #{$navs-space});
  background: $header-background;
  position: fixed;
  top: 0;
  z-index: 200;
  border-bottom: $header-border-bottom;
  @media #{$medium-and-down} {
    h2{
      font-size: 24px;
    }
  }
  @media #{$small-and-down} {
    width: calc(100% - #{$aside-width-small} - #{$navs-space} - #{$navs-space} - #{$navs-space});
    left: calc(#{$aside-width-small} + #{$navs-space} + #{$navs-space});
    h2{
      font-size: 12px;
    }
    .brand{
      width: 120px;
    }
  }
}
