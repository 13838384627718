$alerts-radius: 5px;

.alert-view{
  z-index: 1300;
  position: fixed;
  right: 0;
  top: 0;
  .alert-item{
    padding: .7rem 1rem 1rem;
    background: $color-white;
    border-radius: $alerts-radius;
    margin-top: 1rem;
    margin-right:  1rem;
    white-space: nowrap;
    width: 300px;
    position: relative;
    flex-wrap: nowrap;
    .alert-title{
      white-space: initial;
      line-height: 16px;
      margin-bottom: .5rem;
      margin-top: .5rem;
    }
    .icon-area{
      height: 24px;
      width: 24px;
      min-width: 24px;
    }
    .close-icon-button{
      height: 16px;
      width: 16px;
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }
}
