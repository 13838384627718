$modal-background: color('gray', 'lighten-2');
$modal-background-inverse: $color-white;
$modal-container-primary-background: $color-white;
$modal-container-inverse-background: color('gray', 'lighten-1');
$modal-background-opacity: .6;
$modal-radius: 10px;
$modal-small-max-width: 642px;
$modal-extra-small-max-width: 368px;
$modal-full-max-width: 1200px;

%shared-modal-container {
  z-index: 2;
  border-radius: $modal-radius;
  max-height: 90%;
  width: 90%;
  position: relative;
  margin-top: 1000px;
  opacity: 0;
  @include transition(all .5s);
}
.modal-view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 799;
  width: 100%;
  height: 100%;
  background: rgba($modal-background, $alpha: 0);
  &.primary-modal{
    background: rgba($modal-background, $alpha: 0);
    .modal-container{
      background: $modal-container-primary-background;
    }
  }

  &.inverse-modal{
    background: rgba($modal-background-inverse, $alpha: 0);
    .modal-container{
      background: $modal-container-inverse-background;
    }
  }
  .modal-container {
    @extend %shared-modal-container;
    max-width: $modal-full-max-width;
    min-width: 300px;
    &.AUTO{
      width: auto;
    }
    &.FULL-WIDTH{
      width: 90%;
    }
    &.SMALL{
      max-width: $modal-small-max-width;
      width: 90%;
    }
    &.EXTRA_SMALL{
      width: 90%;
      max-width: $modal-extra-small-max-width;
    }
  }
  .close-area{
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .modal-header{
    position: absolute;
    top: calc((#{$modal-title-height} - 24px) / 2);
    right: 0;
  }
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    @media #{$small-and-down} {
      top: 3px;
    }
  }
  ///// transitions
  @include transition(all .5s);
  &.modal-transition-enter-active{
    background: rgba($modal-background, $alpha: $modal-background-opacity);
    &.inverse-modal{
      background: rgba($modal-background-inverse, $alpha: $modal-background-opacity);
    }
    .modal-container{
      margin-top: 1000px;
      opacity: 0;
    }
  }
  &.modal-transition-enter-done{
    background: rgba($modal-background, $alpha: $modal-background-opacity);
    &.inverse-modal{
      background: rgba($modal-background-inverse, $alpha: $modal-background-opacity);
    }
    .modal-container{
      margin-top: 0;
      opacity: 1;
    }
  }
}
