$aside-background: color('gray', 'base');

body{
  padding-left: calc(#{$aside-width} + #{$navs-space} + #{$navs-space} + 1rem);
  padding-right: 1rem;
  @media #{$small-and-down} {
    padding-left: calc(#{$aside-width-small} + #{$navs-space} + #{$navs-space} + 1rem);
    padding-right: 1rem;
  }
  &:after{
    z-index: -1;
    content: '';
    position: fixed;
    left: $navs-space;
    top: $navs-space;
    background: $aside-background;;
    width: $aside-width;
    @media #{$small-and-down} {
      width: $aside-width-small;
    }border-radius: $radio-default;
    height: calc(100vh - #{$navs-space} - #{$navs-space});
  }
  &.no-nav{
    padding-left: 1rem;
    @media #{$small-and-down} {
      padding-left: 1rem;
    }
    &:after{
      display: none;
    }
  }
}

.aside-navigation {
  position: fixed;
  left: $navs-space;
  top: $navs-space;
  z-index: 10;
  width: $aside-width;
  height: calc(100vh - #{$navs-space} - #{$navs-space});
  display: block;
  @media #{$small-and-down} {
    width: $aside-width-small;
    .btn-text{
      display: none;
    }
  }
  nav{
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        display: block;
        margin: 0;
        padding: .25rem;
        height: $nav-height;
        box-sizing: border-box;
        .nav-item{
          border-radius: $radio-default;
          &.active-nav{
           background: color('gray', 'lighten-2');
          }
          .btn-text{
            font-size: 10px;
          }
        }
      }
    }
  }
}
