$select-label-color: $color-primary;
$select-color: $color-gray;
$select-border-width: 0;
$select-border-style: solid;
$select-border-color: transparent;
$select-background: transparent;
$select-padding-left-right: 0;
$select-radius: $radio-small;
$select-item-hover: color('gray', 'lighten-6');
$select-height: 32px;
$select-width: calc(100%);
$select-item-padding: .5rem;
$select-border-error-color: $color-warning;
$select-disable-opacity: .6;
$select-background-selector: $color-white;
$select-boxshadow-selector: none;
$select-fontsize: $fontsize-default;
$select-border-container: 1px solid color('gray', 'lighten-4');
$select-list-border: 2px solid color('gray', 'lighten-5');

.select-component {
  position: relative;
  padding: .5rem 1rem;
  border-radius: $radio-small;
  border: $select-border-container;
  .label{
    color: $select-label-color;
    text-transform: uppercase;
    font-weight: 300;
    cursor: pointer;
    display: block;
  }
  .select-button {
    color: $select-color;
    border-width: $select-border-width;
    border-style: $select-border-style;
    border-color: $select-border-color;
    background: $select-background;
    font-size: $select-fontsize;
    border-radius: $select-radius;
    padding: 0 $select-padding-left-right;
    line-height: $select-height;
    display: flex;
    width: $select-width;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    .icon-arrow-down {
      font-size: .75em;
      position: absolute;
      right: 1rem;
      top: calc(50% - #{$select-height} / 2);
      &:after, &:before{
        border-color: $select-color;
      }
    }
  }
  .selector-container {
    list-style-type: none;
    position: absolute;
    top: 100%;
    z-index: 499;
    background: $select-background-selector;
    width: 100%;
    left: -2px;
    box-shadow: $select-boxshadow-selector;
    margin: 0;
    padding: 0;
    border-radius: $select-radius;
    border: $select-list-border;
    max-height: 280px;
    overflow-y: auto;
    .selector-item {
      display: flex;
      cursor: pointer;
      a {
        padding: $select-item-padding;
        width: 100%;
      }
      &:hover {
        background: $select-item-hover;
      }
      &.selected {
        background: $select-item-hover;
      }
    }
  }
  .error {
    display: flex;
    justify-content: flex-end;
  }
  &.select-component-error {
    .select-button {
      border-color: $select-border-error-color;
    }
  }
  &.select-component-disable {
    opacity: $select-disable-opacity;
    .select-button, .label {
      cursor: default;
    }
  }
}
