$modal-title-height: 65px;
$footer-height-actions: 84px;

body{
  &.open-modal{
    overflow: hidden;
  }
}

.modal-layout-component{
  padding: 1rem 0 .5rem;
  &.bottom-actions{
    padding-bottom: $footer-height-actions;
  }
  &.title-active{
    .modal-content-area{
      height: calc(100% - #{$modal-title-height});
      max-height: calc(100% - #{$modal-title-height});
    }
  }
  .modal-layout-title{
    height: $modal-title-height;
    min-height: $modal-title-height;
    padding-right: 50px;
  }
  .modal-content-area{
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .content-area{
      padding-bottom: 1rem;
      @media #{$small-and-up} {
        /* width */
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-button{
          width: 6px;
          height: 0;
          opacity: 0;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: rgba($color-secondary, .1);
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $color-secondary;
          border-radius: 3px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: lighten($color-secondary, 40%);
        }
      }
    }
  }
  &.bottom-actions{
    .modal-content-area{
      .content-area{
        padding-bottom: 0;
      }
    }
    .modal-layout-footer{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: $footer-height-actions;
      border-bottom-left-radius: $radio-big;
      border-bottom-right-radius: $radio-big;
      @include flexbox();
      @include flex-direction(row);
      @include align(center);
    }
  }
}
