@mixin useColors($colorList, $type) {
  $prop: background-color;
  @if $type == 'text' {
    $prop: color;
  }
  @if $type == 'border' {
    $prop: border-color;
  }
  @if $type == 'fill' {
    $prop: fill;
  }
  @if $type == 'link' {
    $prop: color;
  }
  @if $type == 'gradient' {
    $prop: background-color;
  }
  @each $color_name, $gen_variants in $colorList {
    @if map-has-key($colors, $color_name) {
      $curr_color: map-get($colors, $color_name);
      @each $color_type, $color_value in $curr_color {
        @if $color_type == 'base' {
          .#{$type}-#{$color_name} {
            #{$prop}: $color_value;
            @if $type == 'link' {
              &:after {
                background: $color_value !important;
              }
            }
            @if $type == 'gradient' {
              overflow: hidden;
              position: relative;
              span,
              .div-top {
                position: relative;
                z-index: 2;
              }
              &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                background: -moz-linear-gradient(
                                50deg,
                                rgba(lighten($color_value, 20%), 0.5) 0%,
                                rgba(darken($color_value, 5%), 1) 100%
                );
                background: -webkit-linear-gradient(
                                50deg,
                                rgba(lighten($color_value, 20%), 0.5) 0%,
                                rgba(darken($color_value, 5%), 1) 100%
                );
                background: linear-gradient(
                                50deg,
                                rgba(lighten($color_value, 20%), 0.5) 0%,
                                rgba(darken($color_value, 5%), 1) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=rgba(lighten($color_value, 20%), .5), endColorstr=rgba(darken($color_value, 5%), 1),GradientType=1 );
              }
            }
            @if $type == 'fill' {
              path {
                fill: $color_value !important;
              }
            }
          }
        } @else if($gen_variants == true) {
          .#{$type}-#{$color_name}.#{$type}-#{$color_type} {
            #{$prop}: $color_value;
            @if $type == 'fill' {
              path {
                fill: $color_value !important;
              }
            }
          }
        }
      }
    }
  }
}
