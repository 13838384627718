$checkbox-list-item-border-width: 1px;
$checkbox-list-item-width-height: 30px;
$checkbox-list-item-padding: .65rem;
$checkbox-list-item-font-color: $color-gray;
$checkbox-list-item-border-color: color('gray', 'lighten-2');
$checkbox-list-item-background-color: $color-white;
$checkbox-list-item-font-size: $fontsize-small;
$checkbox-list-item-check-radius: $radio-small;
$checkbox-list-item-check-color: #ffffff;
$checkbox-list-item-check-error-color: $color-danger;
$checkbox-list-item-font-disable-color: color('gray', 'lighten-3');
$checkbox-list-item-check-disable-color: color('gray', 'lighten-3');
$checkbox-list-item-selected-border-color: $color-secondary;
$checkbox-list-item-selected-background-color: $color-secondary;
$checkbox-list-item-check-border-width: 2px;

.checkbox-component{
  &.checkbox-component-error{
    .check-list-item{
      &:after{
        border-color: $checkbox-list-item-check-error-color;
      }
    }
  }
  &.disabled-checklist{
    .check-list-item{
      &:after, input{
        cursor: default;
      }
      &:after{
        border-color: $checkbox-list-item-check-disable-color;
      }
      &.selected {
        &:after {
          border-color: $checkbox-list-item-check-disable-color;
          background: $checkbox-list-item-check-disable-color;
        }
        &:before{
          cursor: default;
        }
      }
      label{
        color: $checkbox-list-item-font-disable-color;
        cursor: default;
      }
    }
  }
  .check-list-item{
    padding: $checkbox-list-item-padding $checkbox-list-item-padding $checkbox-list-item-padding calc(#{$checkbox-list-item-width-height} + 1rem);
    position: relative;
    &:after, input{
      cursor: pointer;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: calc(-#{$checkbox-list-item-width-height} / 2);
      width: $checkbox-list-item-width-height;
      height: $checkbox-list-item-width-height;
      border: $checkbox-list-item-border-width solid $checkbox-list-item-border-color;
      background: $checkbox-list-item-background-color;
      box-sizing: border-box;
      border-radius: $checkbox-list-item-check-radius;
    }
    input{
      opacity: 0;
      z-index: 5;
    }
    label{
      font-size: $checkbox-list-item-font-size;
      color: $checkbox-list-item-font-color;
      cursor: pointer;
      font-weight: 700;
    }
    &.selected{
      &:after{
        border-color: $checkbox-list-item-selected-border-color;
        background: $checkbox-list-item-selected-background-color;
      }
      &:before{
        cursor: pointer;
        z-index: 2;
        content: '';
        display: block;
        position: absolute;
        left: 11px;
        top: 50%;
        margin-top: -9px;
        width: 8px;
        height: 14px;
        box-sizing: border-box;
        border-color: $checkbox-list-item-check-color;
        border-style: solid;
        border-bottom-width: $checkbox-list-item-check-border-width;
        border-right-width: $checkbox-list-item-check-border-width;
        border-left-width: 0;
        border-top-width: 0;
        @include transform(rotate(40deg))

      }
    }
  }
  .error{
    width: 100%;
    text-align: right;
  }
}
