//box sizing

@mixin box-sizing($args) {
  -webkit-box-sizing: $args;
  -moz-box-sizing: $args;
  box-sizing: $args;
}

//shadow

@mixin box-shadow($args1) {
  -webkit-box-shadow: $args1;
  -moz-box-shadow: $args1;
  box-shadow: $args1;
}

//FlexBox

@mixin flexBox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-direction($value) {
  flex-direction: $value;
  -ms-flex-direction: $value;
  -webkit-flex-direction: $value;
}

//justify

@mixin justify-content($val) {
  -webkit-justify-content: $val;
  justify-content: $val;
}

//FlexWrap

@mixin flex-wrap($value){
  flex-wrap: $value;
  -webkit-flex-wrap: $value;
}

//Flexbox

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

//align

@mixin align($align) {
  -webkit-flex-align: $align;
  -ms-flex-align: $align;
  -webkit-align-items: $align;
  align-items: $align;
}

//gradients

@mixin radial-gradient-deg($deg, $color1, $color2) {
  background-image: -webkit-gradient($deg, $color1, $color2);
  background-image: -webkit-radial-gradient($deg, $color1, $color2);
  background-image: -moz-radial-gradient($deg, $color1, $color2);
  background-image: -ms-radial-gradient($deg, $color1, $color2);
  background-image: -o-radial-gradient($deg, $color1, $color2);
  background-image: radial-gradient($deg, $color1, $color2);
}

//transform

@mixin transform($args) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

//transition

@mixin transition($args: 200ms) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -o-transition: $args;
  -ms-transition: $args;
  transition: $args;
}
