// primary colors
$color-primary: #D8096B;
$color-secondary: #223650;
$color-gray: #1C1C1C;

// aux colors
$color-success: #46b54d;
$color-info: #41e9cd;
$color-warning: #cc8700;
$color-danger: #db1935;

// base colors
$color-white: #ffffff;
$color-black: #000000;
